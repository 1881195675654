<!--充值到其他账户-->
<template>
  <div class="body">
    <div class="topClass margin-leftAuto margin-rightAuto margin-top30 flex_able positionRelative">
      <div class="flex_center flex-directionColumn margin-left33">
        <div class="dots"></div>
        <img style="width: var(--4);height: var(--90);"
             src="https://br-image.oss-cn-beijing.aliyuncs.com/xworld_images/58d69833-a670-4c4f-8440-9876e80204b3.png">
        <div class="dots" style="background: #FC340B;"></div>
      </div>
      <div class="flex_center flex-directionColumn margin-left24 line-height40 flex_1">
        <div class="flex_able" style="width: 100%;height: var(--100);">
          <span class="font-size28 font-color999999">从</span>
          <span class="margin-left12 font-size28 font-color1B1B1B font-weightBold">{{
              firstSixCharacters() + '...' + lastFourCharacters()
            }}</span>
        </div>
        <div class="flex_able" style="width: 100%;padding-right: var(--30);">
          <span class="font-size28 font-color999999">到</span>
          <div class="margin-left12 font-size28 font-color1B1B1B font-weightBold flex_1">
            <van-field v-model="addressB"
                       placeholder="请输入要接收的钱包地址" clearable/>
          </div>
        </div>
      </div>
    </div>
    <div @click="showChoiceRedMoneyType = true" class="margin-left30 margin-right30 margin-top28 flex_able"
         style="border-radius: var(--24);background:#FAFAFA;padding-left: var(--28);padding-right: var(--28);height: var(--96);">
      <div class="font-color1B1B1B font-size28 font-weightBold flex_1">
        {{ redTopNumberDetail }}
      </div>
      <img style="width: var(--16);height: var(--28);"
           src="https://xworld1.oss-cn-beijing.aliyuncs.com/xworld_images/9f380bd3-3271-4941-b9a2-dea984e4376f.png">
    </div>
    <div class="font-size28 font-color1B1B1B font-weightBold margin-left30 margin-right30 margin-top28 flex_able"
         style="border-radius: var(--24);background:#FAFAFA;padding-left: var(--28);padding-right: var(--28);">
      <van-field v-model="coinNumber"
                 placeholder="请填写数量" clearable/>
      <div class="font-size28 font-color1B1B1B margin-left30">
        {{ redTopNumberDetail }}
      </div>
    </div>
    <div class="flex_able margin-top50 margin-right30">
      <div class="margin-left30 font-size28" style="color:#7E90A0;">
        可划转星钻：
      </div>
      <div class="font-size30 font-weightBold font-color026AFC">
        {{ EncryptUtils.formatDecimal2NoZero(balance) }} {{ toAssetTypeName }}
      </div>
    </div>
    <div class="flex_able margin-top22 margin-right30">
      <div class="margin-left30 font-size28" style="color:#7E90A0;">
        手续费：
      </div>
      <div class="font-size30 font-weightBold font-color026AFC">
        {{ EncryptUtils.formatDecimal2NoZero(balance) }} {{ toAssetTypeName }}
      </div>
    </div>
    <div @click="exchange"
         class="flex_center font-size28 font-colorFFFFFF margin-top60 margin-leftAuto margin-rightAuto"
         style="background: #026AFC;width: var(--569);height: var(--88);border-radius: var(--44);">
      划转
    </div>
    <div class="flex_center margin-top30">
      <title-arrow
          textColor="#1b1b1b"
          lineHeight="var(--36)"
          textSize="var(--26)"
          imageWidth="var(--14)"
          imageHeight="var(--24)"
          marginLeft="var(--10)"
          imageUrl="https://xworld1.oss-cn-beijing.aliyuncs.com/xworld_images/9f380bd3-3271-4941-b9a2-dea984e4376f.png"
          text="划转记录"/>
    </div>
    <div style="height: var(--50);"></div>
    <van-action-sheet
        v-model="showChoiceRedMoneyType"
        :actions="actionsMoneyType"
        @select="onSelectMoneyType"
        cancel-text="取消"/>
  </div>
</template>

<script>

import {Api} from "@/utils/net/Api";
import {Toast} from "vant";
import {Constants} from "@/utils/constants";
import EncryptUtils from "@/utils/EncryptUtils";
import TitleArrow from "@/components/TitleArrow.vue";

export default {
  components: {TitleArrow},
  computed: {
    EncryptUtils() {
      return EncryptUtils
    }
  },
  data() {
    return {
      rechargeMethodObjectNumber: {
        selectIndexType: 0,
        gridDataType: []
      },
      serviceChargeProportionReal: 0,
      activityRole: "",
      fromAssetTypeName: "",
      feeAssetTypeName: "",
      balance: "0",
      feeStr: "0",
      exchangeNumber: "",
      maxAmount: "",
      minAmount: "",
      assetType: Constants.Z_COIN_KE_YONG,
      dayUnUsed: "",//可充值数量：
      oldLimit: "",//可充值额度：：
      otcTypeOld: "",//false //true是老用户,才显示oldLimit
      toAssetTypeName: "",
      showChoiceRedMoneyType: false,//显示发红包钱类型
      actionsMoneyType: [
        {
          name: Constants.X_COIN_NAME,
          assetType: Constants.X_COIN,
        }, {
          name: Constants.DOG_COIN,
          assetType: Constants.DOG_COIN_TYPE
        }, {
          name: Constants.SHI_COIN,
          assetType: Constants.SHI_COIN_TYPE
        }
      ],
      redTopNumberDetail: "",
      addressA: "123546742356712354674235671235467423567",
      addressB: "",
      coinNumber: ""
    }
  },
  created() {
    //开始5
    try {
      //设置标题
      document.title = "划转"
      window.Android.setTitleStyle(JSON.stringify({
        titleBarBackgroundColor: '#FFFFFF',
        textColor: '#1B1B1B',
        showTitleBar: "true"
      }));
      window.Android.setStatusBarStyle(JSON.stringify({
        isDark: "true",
        statusBarBgColor: "#FFFFFF",
        showStatusBar: "true"
      }));
    } catch (error) {
      console.log("常规错误:" + error)
    }
    this.onSelectMoneyType(this.actionsMoneyType[0])
    this.queryExchangeConfig()
  },
  methods: {
    firstSixCharacters() {
      return this.addressA.substring(0, 6);
    },
    lastFourCharacters() {
      return this.addressA.slice(-4);
    },
    // 币种类型选择
    onSelectMoneyType(itemTemp) {
      let that = this;
      console.log(itemTemp.name)
      that.showChoiceRedMoneyType = false;
      that.redTopNumberDetail = itemTemp.name
      that.assetType = itemTemp.assetType
      this.queryWithdrawalChainConfig()
    },
    //选择付款方式
    onSelect(item) {
      this.fromAssetTypeOTC = item.name
      this.fromAssetType = item.fromAssetType
      this.showType = false;
      this.queryExchangeConfig()
    },
    handleChange() {
      let itemNumber = this.rechargeMethodObjectNumber.gridDataType[this.rechargeMethodObjectNumber.selectIndexType]
      let value = itemNumber.number
      this.exchangeNumber = value
      //计算手续费
      this.feeStr = EncryptUtils.formatDecimal(value * this.serviceChargeProportionReal)
    },
    queryExchangeConfig() {
      let params = {
        "fromAssetType": this.fromAssetType,
        "toAssetType": Constants.Z_COIN_KE_YONG
      }
      Api.queryExchangeConfig(params).then((result) => {
        if (result.code === 100) {
          console.log(result)
          let entity = result.data.entity
          this.id = result.data.entity.id
          this.fromAssetTypeName = result.data.entity.fromAssetTypeName
          this.serviceChargeProportionReal = result.data.entity.serviceChargeProportionReal
          let activityRole = result.data.entity.confDes
          this.activityRole = activityRole?.replace(/\n/g, "<br>")
          this.feeAssetTypeName = result.data.entity.feeAssetTypeName
          this.dayUnUsed = result.data.entity.dayUnUsed
          this.oldLimit = result.data.entity.oldLimit
          this.otcTypeOld = result.data.entity.otcTypeOld
          this.balance = result.data.entity.balance

          this.rechargeMethodObjectNumber.gridDataType = []
          entity.commonAmounts.forEach((item) => {
            let itemObj = {}
            itemObj["text"] = Constants.Z_VALUE
            itemObj["number"] = item.amount
            this.rechargeMethodObjectNumber.gridDataType.push(itemObj)
          })
          //手动调用一次
          this.handleChange()
        } else {
          Toast(result.msg);
        }
      })
    },
    //兑换
    exchange() {
      this.exchangeDo()
    },
    exchangeDo() {
      let params = {
        id: this.id,
        amount: this.exchangeNumber,
      }
      Api.exchange(params).then((result) => {
        if (result.code === 100) {
          Toast("划转成功")
          this.queryExchangeConfig()
        } else {
          Toast(result.msg);
        }
      })
    },
  },
}
</script>

<style scoped>
.body {
  background: #ffffff;
  width: 100vw;
  display: flex;
  flex-direction: column;
  height: 100vh;
}

.topClass {
  width: var(--690);
  height: var(--192);
  background: #FAFAFA;
  border-radius: var(--24);
}

.dots {
  width: var(--12);
  height: var(--12);
  background: #026AFC;
  border-radius: var(--10);
}

::v-deep .van-action-sheet__header {
  font-weight: bold;
}


/* 设置背景和padding */
::v-deep .van-cell {
  background-color: transparent;
  padding: 0;
  flex: 1;
}

/* 设置 placeholder 颜色 */
::v-deep .van-field__control::placeholder {
  color: #999999;
}

/* 输入框高度 增加热区 */
::v-deep .van-field__control {
  height: var(--100);
}

/* 设置field里面的字体大小 */
::v-deep .van-field__control {
  color: #1b1b1b;
  font-size: var(--28);
  font-weight: 400;
  line-height: var(--40);
}

::v-deep .van-cell::after {
  border-bottom: none;
}
</style>
